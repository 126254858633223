import React, { useState } from "react";
import backgroundImage from "../media/HomePage.jpg";
import sampleImage from "../media/Push.jpg"; // Replace with your actual image path
import serviceImage1 from '../media/Image1.jpg';
import serviceImage2 from '../media/Image2.jpg';
import serviceImage3 from '../media/Image3.jpg';
import Logo from '../media/CompanyLogo.png';

const MainPage = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="relative h-screen bg-black text-white">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          opacity: 0.6,
        }}
      ></div>

      {/* Navigation Bar */}
      <nav className="absolute top-0 left-0 right-0 flex flex-wrap items-center justify-between px-6 py-4 bg-transparent">
        <div className="flex items-center">
          <img
            src={Logo}  // Replace with your logo
            alt="Logo"
            className="mr-2"
          />
        </div>

        {/* Mobile Hamburger Icon */}
        <button
          className="max-[968px]:block hidden text-white focus:outline-none"
          onClick={toggleMobileMenu}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-8 h-8"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>

        {/* Desktop Menu */}
        <ul
          className={`flex space-x-6 uppercase font-semibold text-sm ${
            isMobileMenuOpen ? "flex-col" : "max-[968px]:hidden"
          } max-[968px]:flex`}
        >
          <li className="hover:underline cursor-pointer">Home</li>
          <li className="hover:underline cursor-pointer">Über uns</li>
          <li className="hover:underline cursor-pointer">Dienstleistungen</li>
          <li className="hover:underline cursor-pointer">Referenzen</li>
          <li className="hover:underline cursor-pointer">Projektbeispiele</li>
          <li className="hover:underline cursor-pointer">Kontakt</li>
          <li>🇩🇪</li>
        </ul>
      </nav>

      {/* Main Content Section */}
      <div className="relative z-10 flex flex-col items-center justify-center h-full px-4 text-center">
        <h2 className="text-5xl font-extrabold mb-6">IntuitivAnalytix GmbH</h2>
        <h3 className="text-2xl font-bold mb-4">
          Wir unterstützen Ihre Managemententscheidung &mdash; <br />
          datenbasiert, effizient, transparent
        </h3>
        <p className="max-w-2xl mb-8 text-lg leading-relaxed">
          Nutzen Sie Ihre Daten, um Entscheidungen 80% schneller und besser zu
          treffen als bisher. Unser Ansatz im Bereich Geoinformations- und
          Geoanalysesysteme (GIS), Datenanalytik, Data Science und Künstliche
          Intelligenz hilft Ihnen dabei.
        </p>
        <div className="space-x-4">
          <button className="px-6 py-3 bg-gray-800 hover:bg-gray-700 rounded shadow">
            Mehr Erfahren
          </button>
          <button className="px-6 py-3 bg-gray-600 hover:bg-gray-500 rounded shadow">
            Kontakt Aufnehmen
          </button>
        </div>
      </div>

      {/* Additional Section with Text and Image */}
      <div className="flex flex-col md:flex-row bg-gray-100 p-8 items-center text-gray-800">
        {/* Left Container: Text */}
        <div className="w-full md:w-1/2 p-4">
          <h2 className="text-2xl font-bold mb-4">Die IntuitivAnalytix GmbH</h2>
          <p className="leading-relaxed">
            Die IntuitivAnalytix GmbH ist ein innovatives Unternehmen für
            Geoanalysen, Geoinformationssysteme (GIS), Datenanalytik, Data
            Science und Künstliche Intelligenz. Wir bieten in diesen Bereichen
            Beratung, Software und Schulungen an.
            <br /> <br />
            Wir verfolgen eine Business Canvas getriebene Methodik, um aus Ihren
            Daten schnell marktgerechte Handlungsempfehlungen abzuleiten.
            Ergebnisse und die hierfür notwendigen Entscheidungsfaktoren sind
            bei uns der Ausgangspunkt jedes Projektes. So erreichen wir mit 20%
            der Daten 80% der Lösung. Dabei legen wir großen Wert auf
            Transparenz und intuitives Verständnis der Fakten.
            <br /> <br />
            Im Bereich Geoanalysen nutzen wir state-of-the-art Softwarelösungen
            wie ArcGIS, QGIS und GeoLyx, um die für Sie beste Lösung für Ihr
            Business Problem zu nutzen.
            <br /> <br />
            Unser Ansatz und unsere Software wurden mit der
            Innovationsförderung Push!2024 ausgezeichnet.
            <br /> <br />
            Erfahren Sie, wie Sie von unserem Ansatz profitieren und Ihr
            Unternehmen nach vorne bringen können!
          </p>
        </div>

        {/* Right Container: Image */}
        <div className="w-full md:w-1/2 p-4 flex justify-center">
          <img
            src={sampleImage} // Replace with your image path
            alt="Geoanalysis Example"
            className="rounded-lg shadow-lg w-full md:w-4/5"
          />
        </div>
      </div>
      <div className="bg-blue-100">
      {/* Header Section */}
      <header className="text-center py-8 bg-blue-100">
        <h1 className="text-4xl font-bold text-gray-800">Dienstleistungen</h1>
      </header>

      {/* Services Section */}
      <section className="px-6 py-12 grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Beratung */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <img 
            src={serviceImage1} 
            alt="Beratung"
            className="w-full h-48 object-cover rounded-lg mb-6"
          />
          <h2 className="text-2xl font-semibold text-gray-800">Beratung</h2>
          <ul className="mt-4 text-gray-600 list-disc pl-6">
            <li>Data Science, Data Analytics, Geographische Datenanalysen, Künstliche Intelligenz</li>
            <li>Standardisierter Business Canvas</li>
            <li>Fortschrittlichste Visualisierungstechniken</li>
            <li>Transfer modernster Forschung in Ihr Unternehmen</li>
          </ul>
          <a href="#more" className="mt-4 text-blue-600">Mehr Erfahren &rarr;</a>
        </div>

        {/* Geoanalysen / GIS / Software */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <img 
            src={serviceImage2} 
            alt="Geoanalysen"
            className="w-full h-48 object-cover rounded-lg mb-6"
          />
          <h2 className="text-2xl font-semibold text-gray-800">Geoanalysen / GIS / Software</h2>
          <ul className="mt-4 text-gray-600 list-disc pl-6">
            <li>Unsere Geoanalysen führen zu verständlichen und durchführbaren Handlungsempfehlungen</li>
            <li>Auswahl von etablierten Lösungen wie ArcGIS, QGIS</li>
            <li>Nutzung unserer kinderleicht zu bedienenden Excel basierten GeoLyx Software</li>
          </ul>
          <a href="#more" className="mt-4 text-blue-600">Mehr Erfahren &rarr;</a>
        </div>

        {/* Schulung */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <img 
            src={serviceImage1} 
            alt="Schulung"
            className="w-full h-48 object-cover rounded-lg mb-6"
          />
          <h2 className="text-2xl font-semibold text-gray-800">Schulung</h2>
          <ul className="mt-4 text-gray-600 list-disc pl-6">
            <li>Data Science / Datenanalytik</li>
            <li>Geodatenanalysen</li>
            <li>Excel-Modelling</li>
            <li>Projektmanagement</li>
          </ul>
          <a href="#more" className="mt-4 text-blue-600">Mehr Erfahren &rarr;</a>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="bg-blue-500 text-white py-6 text-center">
        <h3 className="text-xl font-semibold">Kontaktieren Sie uns</h3>
        <p className="mt-2">Haben Sie Fragen oder möchten mehr darüber erfahren, wie wir Ihnen helfen können? Wir freuen uns darauf, von Ihnen zu hören!</p>
        <button className="mt-4 bg-black px-6 py-3 rounded text-white">Jetzt Kontakt Aufnehmen</button>
      </footer>
    </div>
    </div>
  );
};

export default MainPage;
