import React from "react";
import MainPage from "./components/MainPage";

function App() {
  return (
    <div>
      

      <MainPage />
    </div>
  );
}

export default App;
